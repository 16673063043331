import { useQuery } from "@tanstack/react-query";

import { fetchPupilsWithCategories } from "api/pupils/fetchPupilsWithRiskGroups";

export function useFetchPupilsWithCategories() {
    const { data, error, isLoading } = useQuery({
        queryKey: ["pupils/with-categories"],
        queryFn: fetchPupilsWithCategories,
    });

    return {
        pupilsWithCategories: data,
        pupilsWithCategoriesError: error,
        pupilsWithCategoriesIsLoading: isLoading,
    };
}
